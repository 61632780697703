export const getFragment = (key: string, defaultValue = ""): string => {
  const fragmentRegExp = new RegExp(`[\\#&]${key}=([^&#]*)`);
  const result = fragmentRegExp.exec(window.location.hash);

  if (result && result.length >= 2) {
    return decodeURIComponent(result[1]);
  }

  return defaultValue;
};
